import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {RootState, useAppSelector} from "../../store";
import { Flex, Menu } from "@chakra-ui/react";
import { ChevronDownIcon, DownloadIcon } from "@chakra-ui/icons";
import {
  setTableState,
} from "../../store/slices/estimates";
import { OutlineButton, PrimaryButton } from "../lib/button/Button";
import { OutlineMenuButton } from "../lib/menu/ChakraMenuButton";
import { BlockTitle } from "../lib/title/Title";
import { ChakraMenuList } from "../lib/menu/ChakraMenuList";
import { EstimateTable } from "./EstimateTable";
import {BeatLoader} from "../lib/beatLoader/BeatLoader";
import {Loader} from "../Loader/Loader";
import {checkPermission} from "../../helpers/checkPermission";
import { downloadExportEstimate } from "../../helpers/downloadExportEstimate";
import { FullScreenWaitingModal } from "../Modal/FullScreenWaitingModal";
import { AccessRights } from "../../shared/mock/sidebarContent";
import { isDisabled } from "@testing-library/user-event/dist/utils";

export function EstimateBlock({ dispatch, navigate }: any) {
  const { estimates, currentEstimate } = useAppSelector(
    (state) => state.estimates
  );
  const { currentContract } = useAppSelector(
    (state: RootState) => state.contracts
  );
  const { permissions } = useAppSelector(
    (state: RootState) => state.auth.authData
  );

  const REGISTER_STATUS = "Зарегистрирован"
  const isRegistered = () => {return currentContract?.status == REGISTER_STATUS}

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSpinnerRunning, setIsSpinnerRunning] = useState<boolean>(false);
  const [exportSpinnerRun, setExportSpinnerRun] = useState<boolean>(false)

  const { pathname } = useLocation();

  const goToCreatePage = (path: string) => {
    navigate(`/purchase/${path}/create`)
  }

  const materialMenuList = [
    {
      title: "Акт об экономии",
      onClick: () => goToCreatePage("compensation-act"),
      isDisabled: !checkPermission(AccessRights.Purchase_RW, permissions) || !isRegistered()
    },
    {
      title: "Лимитно-заборная карта",
      onClick: () => goToCreatePage("stock-cards"),
      isDisabled: !checkPermission(AccessRights.Purchase_RW, permissions) || !isRegistered()
    },
    {
      title: "Распорядительное письмо",
      onClick: () => goToCreatePage("order-letters"),
      isDisabled: true
    }
  ];

  const handleCreateClick = () => {
    setIsSpinnerRunning(true);
  }

  const handleExportClick = () => {
    currentEstimate && downloadExportEstimate(currentEstimate.id, [], setExportSpinnerRun)
  }

  useEffect( () => {
    dispatch(setTableState(null))
  }, [])

  useEffect(() => {
    if (isSpinnerRunning) {
      navigate(`${pathname}/create-act`);
    }
  }, [isSpinnerRunning, navigate, pathname]);

  return (
    <>
      <Flex direction="column" gap={4} h="full">
        <BlockTitle>Сметы/спецификации</BlockTitle>
        <Flex justify="space-between">
          <Flex gap={3}>
              <PrimaryButton
                isDisabled={!currentEstimate || currentContract?.is_archived || !isRegistered() || !checkPermission(AccessRights.WorkCompletedAct_RW, permissions)}
                spinner={<BeatLoader />}
                isLoading={isLoading}
                onClick={handleCreateClick}
              >
                Создать новый акт
              </PrimaryButton>
              <Menu>
                <OutlineMenuButton isDisabled={currentContract?.is_archived || !checkPermission(AccessRights.Purchase_RW, permissions)} rightIcon={<ChevronDownIcon/>}>
                  Материалы
                </OutlineMenuButton>
                <ChakraMenuList list={materialMenuList}/>
              </Menu>
          </Flex>
          <OutlineButton 
            isDisabled={!currentEstimate || currentContract?.is_archived || !isRegistered()  || !checkPermission(AccessRights.WorkCompletedAct_RW, permissions)}
            leftIcon={<DownloadIcon/>}
            onClick={handleExportClick}
          >
            Скачать в Excel
          </OutlineButton>
        </Flex>
        <EstimateTable data={estimates} setIsLoading={setIsLoading}/>
      </Flex>

      {isSpinnerRunning &&
        <Loader isLoading={isSpinnerRunning}/>
      }
      <FullScreenWaitingModal openModal={exportSpinnerRun} />
    </>
  );
}
