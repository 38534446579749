import { Box, Flex, Input, Select } from "@chakra-ui/react";
import { ChevronDownIcon, DownloadIcon } from "@chakra-ui/icons";
import { InformationBlock } from "../InformationBlock/InformationBlock";
import { InformationItem } from "../InformationBlock/InformationItem";
import { InformationItemForm } from "../InformationBlock/InformationItemForm";
import { RootState, useAppSelector } from "../../store";
import { OutlineButton, PrimaryButton } from "../lib/button/Button";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import dayjs from "dayjs";
import { reverseDate } from "../../helpers/reverseDate";
import { fetchPrintForm } from "../../store/slices/directionLetters";
import { FullScreenWaitingModal } from "../Modal/FullScreenWaitingModal";
import { useUniversalDownloadFile } from "../../helpers/useUniversalDownloadFile";

interface AdditionalInformationBlockProps {
  setFilesModal: (arg: boolean) => void;
}

const testData = [
  {
    guid: "b0d4ce1d-1157-4699-138c-cfa72ba94f86",
    number: "1",
    bic: "123456s78901",
    bank_name: "ЧЕЛЯБИНСКОЕ ОТДЕЛЕНИЕ",
  },
  {
    guid: "b0d4ce1d-1157-4699-138c-cfa72ssba94f86",
    number: "2",
    bic: "123456sfsf78901",
    bank_name: "ПАО СБЕРБАНК",
  },
];

export function AdditionalInformationBlock({
  setFilesModal,
}: AdditionalInformationBlockProps) {
  const {
    currentSupplier,
    currentDirectionLetter,
  } = useAppSelector((state: RootState) => state.directionLetters);
  const { downloadFile } = useUniversalDownloadFile();

  const isDisabled = currentDirectionLetter?.has_approved_visa && currentDirectionLetter.status !== "Рабочий"

  const [selectedData, setSelectedData] = useState<any>();
  const [isSpinnerRuning, setIsSpinnerRuning] = useState(false);

  const onSubmit = (values: any) => {};

  const { setFieldValue, ...formik } = useFormik({
    initialValues: {
      supplier_director_full_name: currentDirectionLetter
        ? currentDirectionLetter.supplier_director_full_name
        : currentSupplier?.director_full_name,
      supplier_director_role: currentDirectionLetter
        ? currentDirectionLetter.supplier_director_role
        : currentSupplier?.director_role,
      payment_assignment: currentDirectionLetter
        ? currentDirectionLetter.payment_assignment
        : "",
      invoice_number: currentDirectionLetter?.invoice_number ?? "",
      invoice_date: "",
      order_number: currentDirectionLetter?.order_number ?? "",
      order_date: "",
      supplier_contract_number:
        currentDirectionLetter?.supplier_contract_number ?? "",
      supplier_contract_date: "",
      specification_number: currentDirectionLetter?.specification_number ?? "",
      specification_date: "",
    },
    onSubmit,
  });

  useEffect(() => {
    setFieldValue(
      "supplier_director_full_name",
      currentDirectionLetter?.supplier_director_full_name ??
        currentSupplier?.director_full_name
    );
    setFieldValue(
      "supplier_director_role",
      currentDirectionLetter?.supplier_director_role ??
        currentSupplier?.director_role
    );
    setFieldValue(
      "payment_assignment",
      currentDirectionLetter?.payment_assignment ?? ""
    );

    setFieldValue(
      "invoice_number",
      currentDirectionLetter?.invoice_number ?? ""
    );
    setFieldValue(
      "invoice_date",
      currentDirectionLetter
        ? dayjs(reverseDate(currentDirectionLetter?.invoice_date)).format(
            "YYYY-MM-DD"
          )
        : ""
    );

    setFieldValue("order_number", currentDirectionLetter?.order_number ?? "");
    setFieldValue(
      "order_date",
      currentDirectionLetter
        ? dayjs(reverseDate(currentDirectionLetter?.order_date)).format(
            "YYYY-MM-DD"
          )
        : ""
    );

    setFieldValue(
      "supplier_contract_number",
      currentDirectionLetter?.supplier_contract_number ?? ""
    );
    setFieldValue(
      "supplier_contract_date",
      currentDirectionLetter
        ? dayjs(
            reverseDate(currentDirectionLetter?.supplier_contract_date)
          ).format("YYYY-MM-DD")
        : ""
    );

    setFieldValue(
      "specification_number",
      currentDirectionLetter?.specification_number ?? ""
    );
    setFieldValue(
      "specification_date",
      currentDirectionLetter
        ? dayjs(reverseDate(currentDirectionLetter?.specification_date)).format(
            "YYYY-MM-DD"
          )
        : ""
    );
  }, [setFieldValue, currentDirectionLetter, currentSupplier]);

  function handleChange(data: any) {
    const selectedObject = testData.find(
      (item) => item.guid === data.target.value
    );
    setSelectedData(selectedObject);
  }

  return (
    <Flex flexDirection="column" gap="16px">
      <InformationBlock>
        <InformationItem title={`Р/С  ${currentDirectionLetter?.supplier_name}`}>
          <Box
            position="absolute"
            top="50%"
            transform="translate(0, -50%)"
            w="full"
          >
            <Select
              h="36px"
              borderColor="#E3E7EF"
              placeholder={`${currentDirectionLetter?.checking_account_number} (${currentDirectionLetter?.checking_account_bic})`}
              icon={<ChevronDownIcon color="#2AB6A5 !important" />}
              onChange={handleChange}
              isDisabled={true}
            >
              {testData.map((item) => (
                <option key={item.guid} value={item.guid}>
                  {item.bank_name} ({item.number})
                </option>
              ))}
            </Select>
          </Box>
        </InformationItem>
        <InformationItem title="Счет «СУ №112 ООО»">
          <InformationItemForm
            firstId="invoice_number"
            secondId="invoice_date"
            firstValue={formik.values.invoice_number}
            secondValue={formik.values.invoice_date}
            onChange={formik.handleChange}
            isDisabled={isDisabled}
          />
        </InformationItem>
        <InformationItem title="Номер заявки">
          <InformationItemForm
            firstId="order_number"
            secondId="order_date"
            firstValue={formik.values.order_number}
            secondValue={formik.values.order_date}
            onChange={formik.handleChange}
            isDisabled={isDisabled}
          />
        </InformationItem>
        <InformationItem title="Договор с поставщиком">
          <InformationItemForm
            firstId="supplier_contract_number"
            secondId="supplier_contract_date"
            firstValue={formik.values.supplier_contract_number}
            secondValue={formik.values.supplier_contract_date}
            onChange={formik.handleChange}
            isDisabled={isDisabled}
          />
        </InformationItem>
        <InformationItem title="Спецификация">
          <InformationItemForm
            firstId="specification_number"
            secondId="specification_date"
            firstValue={formik.values.specification_number}
            secondValue={formik.values.specification_date}
            onChange={formik.handleChange}
            isDisabled={isDisabled}
          />
        </InformationItem>
        <InformationItem title="Руководитель">
          <Input
            id="supplier_director_full_name"
            name="supplier_director_full_name"
            h="36px"
            rounded="md"
            borderColor="#E3E7EF"
            position="absolute"
            top="50%"
            transform="translate(0, -50%)"
            value={formik.values.supplier_director_full_name}
            onChange={formik.handleChange}
            isDisabled={isDisabled}
          />
        </InformationItem>
        <InformationItem title="Должность руководителя">
          <Input
            id="supplier_director_role"
            name="supplier_director_role"
            defaultValue={currentSupplier?.director_role}
            h="36px"
            rounded="md"
            borderColor="#E3E7EF"
            position="absolute"
            top="50%"
            transform="translate(0, -50%)"
            value={formik.values.supplier_director_role}
            onChange={formik.handleChange}
            isDisabled={isDisabled}
          />
        </InformationItem>
        <InformationItem title="БИК">{currentDirectionLetter?.checking_account_bic}</InformationItem>
        <InformationItem title="Название отделения банка">
          {currentDirectionLetter?.checking_account_back_name}
        </InformationItem>
        <InformationItem title="Назначение платежа">
          <Input
            id="payment_assignment"
            name="payment_assignment"
            h="36px"
            rounded="md"
            borderColor="#E3E7EF"
            position="absolute"
            top="50%"
            transform="translate(0, -50%)"
            isDisabled={isDisabled}
            value={formik.values.payment_assignment}
            onChange={formik.handleChange}
          />
        </InformationItem>
      </InformationBlock>
      <Flex gap="12px">
        <PrimaryButton isDisabled={isDisabled} onClick={() => setFilesModal(true)}>
          Прикрепить письмо
        </PrimaryButton>
        <OutlineButton isDisabled={isDisabled} onClick={() => downloadFile(currentDirectionLetter?.guid, setIsSpinnerRuning, fetchPrintForm, {type: "pdf"})} leftIcon={<DownloadIcon />}>Скачать в PDF</OutlineButton>
      </Flex>
      <FullScreenWaitingModal openModal={isSpinnerRuning} />
    </Flex>
  );
}
