import {
  Box,
  ButtonGroup,
  Flex,
  Grid,
  GridItem, Icon
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import {
  OutlineButton,
  PrimaryButton,
} from "../../components/lib/button/Button";
import { BlockTitle } from "../../components/lib/title/Title";
import { MainLayout } from "../../components/MainLayout/MainLayout";
import { Comment } from "../../components/Comment/Comment";
import {RootState, useAppSelector} from "../../store";
import {useCallback, useEffect, useState} from "react";
import {
  addSingleFenceCard, attachFilesToSingleFenceCard,
  resetCurrentLimitFenceCard,
} from "../../store/slices/limitFenceCards";
import { Input } from "../../components/lib/input/Input";
import { Loader } from "../../components/Loader/Loader";
import {
  CreateStockCardInformationBlock
} from "../../components/CreateStockCardComponents/CreateStockCardInformationBlock";
import {resetCurrentContract} from "../../store/slices/contracts";
import { ReactComponent as FileShare} from "../../images/svg/file/share.svg";
import { DownloadFilesModal } from "../../components/Modal/ModalRight/DownloadFilesModal";
import {StockCardPageBreadcrumbs} from "../../components/Breadcrumbs/StockCardPageBreadcrumbs";
import {CloseAlertModal} from "../../components/CloseAlertModal/CloseAlertModal";
import {ISingleLimitFenceCardResponse} from "../../api/limitFenceCards/types";
import { MaterialsTable } from "../../components/SingleOrderLetterComponents/MaterialsTable";
import { AccessRights } from "../../shared/mock/sidebarContent";
import { OnboardingByPermission } from "../../components/OnboardingByPermission/OnboardingByPermission";

export function CreateStockCardPage({ dispatch, filesModal, setFilesModal, goBack }: any) {
  const [searchValue, setSearchValue] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [files, setFiles] = useState<File[]>([]);
  const [closeAlertModalOpen, setCloseAlertModalOpen] = useState<boolean>(false);

  const { currentLimitFenceCard } = useAppSelector(
    (state: RootState) => state.limitFenceCards
  );
  const { currentContract } = useAppSelector(
    (state: RootState) => state.contracts
  );

  const [dataState, setDataState] = useState({
    contract_id: currentContract?.id.toString() ?? "",
    period_from: "",
    period_to: "",
    comment: "",
    create_rp: false
  });

  const [isRunSpinner, setIsRunSpinner] = useState(false);


  const onChangeData = useCallback((
    period_from_value: string,
    period_to_value: string,
    contract_id_value: string,
    create_rp: boolean,
  ) => {
    setDataState((dataState) => ({
      ...dataState,
      period_from: period_from_value,
      period_to: period_to_value,
      contract_id: contract_id_value,
      create_rp: create_rp
    }));
  }, []);

  const fileAttach = (id: number) => {
    dispatch(attachFilesToSingleFenceCard({id: id, files: files}))
      .then(() => goBack())
      .catch((e: Error) => {
        console.error('Error: ', e);
      })
      .finally(() => {
        setIsRunSpinner(false);
      });
  }

  const onSubmit = () => {
    setIsRunSpinner(true);

    dispatch(addSingleFenceCard(dataState))
      .then((res: { payload: ISingleLimitFenceCardResponse; }) => {
        if (files.length) {
          fileAttach((res.payload as ISingleLimitFenceCardResponse)?.data.id);
        } else {
          goBack()
        }
      })
      .catch((e: Error) => {
        console.error('Error: ', e);
      })
      .finally(() => {
        files.length === 0 && setIsRunSpinner(false);
      });
  };

  const handleChangeComment = useCallback((comment_value: string) => {
    setDataState((dataState) => ({
      ...dataState,
      comment: comment_value,
    }));
  }, []);

  const onBackBtnClick = () => {
    if (files.length) {
      setCloseAlertModalOpen(true);
    } else {
      goBack();
    }
  }

  useEffect(() => {
    return () => {
      dispatch(resetCurrentLimitFenceCard());
      dispatch(resetCurrentContract());
    }
  }, [dispatch]);

  useEffect(() => {
    if (currentContract) {
      setDataState((dataState) => ({
        ...dataState,
        contract_id: currentContract.id.toString(),
      }));
    }
  }, [currentContract]);

  return (
    <MainLayout>
      <Flex direction="column" gap={10} p={6}>
        <Flex direction="column" gap={4}>
          <StockCardPageBreadcrumbs currentPageText="Создать лимитно-заборную карту" />

          <Flex justify="space-between" align="center">
            <Flex align="center" gap={4}>
              <ArrowBackIcon boxSize={6} cursor="pointer" onClick={onBackBtnClick} />
              <BlockTitle>Создать лимитно-заборную карту</BlockTitle>
            </Flex>
            <ButtonGroup spacing={3}>
              <OutlineButton isDisabled>Согласовать</OutlineButton>
              <OutlineButton isDisabled>Создать отражение факта поставки</OutlineButton>
              <OutlineButton isDisabled>Перезапустить</OutlineButton>
              <PrimaryButton isDisabled={disabled} onClick={onSubmit}>
                Записать
              </PrimaryButton>
            </ButtonGroup>
          </Flex>
          <Grid templateColumns="1fr 504px" gap={4}>
            <GridItem>
              <CreateStockCardInformationBlock onChangeData={onChangeData} setDisabled={setDisabled} />
            </GridItem>
            <GridItem>
              <Comment currentComment={currentLimitFenceCard?.comment} handleChangeComment={handleChangeComment} isEdited />
            </GridItem>
          </Grid>
          <Flex pt={6} gap={4} w="full" direction="column">
            <Flex justify="space-between">
              <Flex gap={6} align="center" maxW="458px">
                <BlockTitle>Материалы</BlockTitle>
                <Input
                  placeholder="Поиск"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  search
                />
              </Flex>
              <ButtonGroup spacing={3}>
                <OutlineButton leftIcon={<Icon as={FileShare} />} onClick={() => setFilesModal(true)}>Файлы</OutlineButton>
                <PrimaryButton isDisabled>Разбить период</PrimaryButton>
              </ButtonGroup>
            </Flex>
            <MaterialsTable searchValue={searchValue} />
          </Flex>
        </Flex>

        <Box>
          <OnboardingByPermission permission={AccessRights.Purchase_RW} />
        </Box>

      </Flex>
      {isRunSpinner && <Loader isLoading={isRunSpinner} />}
      <DownloadFilesModal
        title="Файлы"
        active={filesModal}
        onClose={() => setFilesModal(false)}
        setFilesOnCreate={setFiles}
      />
      <CloseAlertModal isOpen={closeAlertModalOpen} onClose={() => setCloseAlertModalOpen(false)} closeDrawer={goBack} />
    </MainLayout>
  );
}
