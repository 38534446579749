import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {
  Divider,
  Flex,
  Menu,
  MenuList,
  Modal, ModalBody,
  ModalContent, ModalFooter,
  ModalOverlay, Text,
  useDisclosure, ModalHeader, MenuItem
} from "@chakra-ui/react";
import { Title } from "../../components/lib/title/Title";
import { MainLayout } from "../../components/MainLayout/MainLayout";
import { LinkTabs } from "../../components/lib/tabs/Tabs";
import { purchasePageTabs } from "../../shared/mock/purchasePageData";
import {OutlineButton, PrimaryButton} from "../../components/lib/button/Button";
import { useEffect, useState } from "react";
import { CompensationActModal } from "../../components/CompensationActModal/CompensationActModal";
import {OutlineMenuButton, PrimaryMenuButton} from "../../components/lib/menu/ChakraMenuButton";
import {ChevronDownIcon} from "@chakra-ui/icons";
import {ChakraMenuItem} from "../../components/lib/menu/ChakraMenuList";
import {ChakraModalProps} from "../../shared/types/modalProps";
import styles from "../../components/Modal/ModalRight/ModalHeader/modal-header.module.scss";
import { ReactComponent as Cross } from "../../images/svg/cross/cross.svg";
import {RootState, useAppDispatch, useAppSelector} from "../../store";
import {resetCurrentLimitFenceCard} from "../../store/slices/limitFenceCards";
import {resetCurrentContract} from "../../store/slices/contracts";
import { IDirectionLetterSuppliers } from "../../api/directionLetters/types";
import { changeCurrentSupplier } from "../../store/slices/directionLetters";

const supplierCompanies = [
  {
    id: "1",
    title: "Скандинавия",
  },
  {
    id: "2",
    title: "Прокшино",
  },
  {
    id: "3",
    title: "Испанские кварталы",
  },
  {
    id: "4",
    title: "Белые ночи",
  },
  {
    id: "5",
    title: "Южные сады",
  }
]

export function PurchasePage() {
  const [isCompensationAct,setIsCompensationAct] = useState(false);
  const [isStockCards,setIsStockCards] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isSupplierCompanyModalOpen, onClose: onSupplierCompanyModalClose, onOpen:onSupplierCompanyModalOpen } = useDisclosure();
  const { currentLimitFenceCard } = useAppSelector((state: RootState) => state.limitFenceCards);
  const dispatch = useAppDispatch();

  const onCreateStockCard = () => {
    dispatch(resetCurrentLimitFenceCard());
    dispatch(resetCurrentContract());
    navigate(`${location.pathname}/create`);
  }

  useEffect(() => {
    setIsCompensationAct(location.pathname.includes('compensation-act'))
    setIsStockCards(location.pathname.includes('stock-cards'))
  },[location])
  
  return (
    <MainLayout>
      <Flex overflowY="scroll" maxH="100%" direction="column" gap={4} p="6">
        <Flex justifyContent="space-between">
          <Title>Закупки</Title>
          {isStockCards &&
            <Flex gap="12px" align="center">
              <Menu>
                <PrimaryMenuButton rightIcon={<ChevronDownIcon />}>
                  Создать
                </PrimaryMenuButton>
                <MenuList border="1px solid #E3E7EF" zIndex={2} p={1}>
                  <ChakraMenuItem
                    onClick={onSupplierCompanyModalOpen}
                    isDisabled={true}
                  >
                    Создать распорядительное письмо
                  </ChakraMenuItem>
                  <Divider />
                  <ChakraMenuItem
                    onClick={onCreateStockCard}
                  >
                    Создать новую ЛЗК
                  </ChakraMenuItem>
                </MenuList>
              </Menu>
              <OutlineButton isDisabled={!currentLimitFenceCard} onClick={() => navigate(`${location.pathname}/create`)}>Дублировать ЛЗК</OutlineButton>
            </Flex>
          }
          {isCompensationAct &&
            <PrimaryButton onClick={onOpen} >Создать</PrimaryButton>
          }

        </Flex>
        <LinkTabs tabs={purchasePageTabs} />
        <Outlet />
      </Flex>
      <CompensationActModal isAok={true} isInsideCreate={false} isOpen={isOpen} onClose={onClose} />
      <SupplierCompanyModal isOpen={isSupplierCompanyModalOpen} onClose={onSupplierCompanyModalClose} />
    </MainLayout>
  );
}

function SupplierCompanyModal({onClose, isOpen}: ChakraModalProps) {
  const dispatch = useAppDispatch();
  const [selectValue, setSelectValue] = useState<IDirectionLetterSuppliers | undefined>();
  const navigate = useNavigate();
  const { directionLetterSuppliers } = useAppSelector((state: RootState) => state.directionLetters);

  useEffect(() => {
    if (selectValue) {
      dispatch(changeCurrentSupplier(selectValue));
    }
  }, [selectValue])

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(3px)"/>
      <ModalContent maxW="520px" gap="24px" p="24px">
        <ModalHeader p={0}>
          <Flex justifyContent="space-between" align="center">
            <Text
              fontSize="24px"
              color="#1F222A"
              lineHeight="28px"
              letterSpacing="-1.5%"
              fontWeight={500}>
              Выберите компанию поставщика
            </Text>
            <span className={styles.cross_wrapper} onClick={onClose}>
              <Cross/>
            </span>
          </Flex>
        </ModalHeader>
        <ModalBody p={0}>
          <Menu>
            <OutlineMenuButton w="100%" h="40px" rightIcon={<ChevronDownIcon color="#5C657E !important"/>}
                               textAlign="left"
                               color="#5C657E" borderColor="#E3E7EF" padding="10px 12px" fontWeight={400}
                               _active={{ bg: "#FFFFFF", borderColor: "#E3E7EF" }} >
              {selectValue?.name ?? "Выберите из списка"}
            </OutlineMenuButton>
            <MenuList border="1px solid #E3E7EF" marginTop="-9px" borderTopRadius={0} p={0} maxHeight="256px" overflowY="scroll">
              {directionLetterSuppliers.map(supplierCompany => (
                <MenuItem fontWeight={400} key={supplierCompany.guid} onClick={() => setSelectValue(supplierCompany)}>{supplierCompany.name}</MenuItem>
              ))}
            </MenuList>
          </Menu>

        </ModalBody>
        <ModalFooter p={0}>
          <Flex w="100%" gap="12px" align="center" justifyContent="flex-end">
            <OutlineButton onClick={onClose}>
              Отмена
            </OutlineButton>
            <PrimaryButton onClick={() => navigate('/purchase/order-letters/create')}>
              Выбрать
            </PrimaryButton>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
