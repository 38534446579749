import { AxiosPromise } from "axios";
import { axiosInstance } from "../instance";
import { DirectionLettersEndpoints } from "../endpoints";
import {
  IDirectionLetterCheckingAccountsResponse,
  IDirectionLettersResponse,
  IDirectionLetterSuppliersResponse,
  IPrintFormResponse,
  ISingleDirectionLetterResponse,
} from "./types";

export const getDirectionLetters =
  (): AxiosPromise<IDirectionLettersResponse> =>
    axiosInstance.get(DirectionLettersEndpoints.DIRECTION_LETTERS);

export const getDirectionLettersByLimitFenceCard = (
  id: number
): AxiosPromise<IDirectionLettersResponse> =>
  axiosInstance.get(DirectionLettersEndpoints.DIRECTION_LETTERS + `/${id}`);

export const getDirectionLettersByContract = (
  id: number
): AxiosPromise<IDirectionLettersResponse> =>
  axiosInstance.get(DirectionLettersEndpoints.DIRECTION_LETTERS + `/${id}`);

export const getSingleDirectionLetter = (
  id: string
): AxiosPromise<ISingleDirectionLetterResponse> =>
  axiosInstance.get(DirectionLettersEndpoints.DIRECTION_LETTER + `/${id}`);

export const getDirectionLetterSuppliers = (
  id: number
): AxiosPromise<IDirectionLetterSuppliersResponse> =>
  axiosInstance.get(DirectionLettersEndpoints.DIRECTION_LETTER + `/${id}/suppliers`);

export const getSupplierCheckingAccounts= (
  id: string
): AxiosPromise<IDirectionLetterCheckingAccountsResponse> =>
  axiosInstance.get(DirectionLettersEndpoints.DIRECTION_LETTER + `/${id}/supplier-checking-accounts`);

export const getPrintForm = (
  id: string, params: {type: string}
): AxiosPromise<IPrintFormResponse> =>
  axiosInstance.post(DirectionLettersEndpoints.DIRECTION_LETTER + `/${id}/request-print-form`, params);