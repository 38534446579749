import {PayloadAction, createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import api from "../../api";
import { createAsyncThunkInstance } from "../createAsyncThunkInstance";
import { isError } from "..";
import {
  ICreateLimitFenceCardRequest,
  ILimitFenceCard,
  ILimitFenceCardsResponse,
  ISingleLimitFenceCardResponse, IUpdateLimitFenceCardRequest,
} from "../../api/limitFenceCards/types";
import { ErrorType, ICommonInitialState } from "../../shared/types/storeTypes";
import {AxiosError} from "axios";
import {
  approveLimitFenceCard,
  createLimitFenceCard,
  updateLimitFenceCard
} from "../../api/limitFenceCards";
import {IFilesAttachResponse} from "../../api/files/types";
import { attachFiles } from "../../api/files";
import { Files, LimitFenceCardsEndpoints } from "../../api/endpoints";
import { IContract, IContractsResponse } from "../../api/contracts/types";

interface IInitialLimitFenceCardsState extends ICommonInitialState {
  limitFenceCards: ILimitFenceCard[];
  projectLimitFenceCards: ILimitFenceCard[];
  contractLimitFenceCards: ILimitFenceCard[];
  currentLimitFenceCard: ILimitFenceCard | null;
  contractsLimitFenceCard: IContract[];
}

const initialState: IInitialLimitFenceCardsState = {
  limitFenceCards: [],
  projectLimitFenceCards: [],
  contractLimitFenceCards: [],
  currentLimitFenceCard: null,
  contractsLimitFenceCard: [],
  isLoading: false,
  error: null,
};

export const fetchLimitFenceCards = createAsyncThunkInstance<
  ILimitFenceCardsResponse,
  undefined
>(
  "limitFenceCards/fetchLimitFenceCards",
  api.limitFenceCards.getLimitFenceCards
);

export const fetchLimitFenceCardsByProject = createAsyncThunkInstance<
  ILimitFenceCardsResponse,
  number
>(
  "limitFenceCards/fetchLimitFenceCardsByProject",
  api.limitFenceCards.getLimitFenceCardsByProject
);

export const fetchLimitFenceCardsByContract = createAsyncThunkInstance<
  ILimitFenceCardsResponse,
  number
>(
  "limitFenceCards/fetchLimitFenceCardsByContract",
  api.limitFenceCards.getLimitFenceCardsByContract
);

export const fetchLimitFenceCardContracts = createAsyncThunkInstance<
  IContractsResponse,
  undefined
>(
  "limitFenceCards/fetchLimitFenceCardContracts",
  api.limitFenceCards.getLimitFenceCardContracts
);

export const fetchSingleLimitFenceCard = createAsyncThunkInstance<
  ISingleLimitFenceCardResponse,
  number
>(
  "limitFenceCards/fetchSingleLimitFenceCard",
  api.limitFenceCards.getSingleLimitFenceCard
);

export const addSingleFenceCard = createAsyncThunk<
  ISingleLimitFenceCardResponse,
  ICreateLimitFenceCardRequest,
  { rejectValue: AxiosError }
>(
  "limitFenceCards/addSingleFenceCard",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await createLimitFenceCard(params);

      return data;
    } catch (error: unknown) {
      return rejectWithValue(error as AxiosError);
    }
  }
);

export const updateSingleFenceCard = createAsyncThunk<
  ISingleLimitFenceCardResponse,
  IUpdateLimitFenceCardRequest,
  { rejectValue: AxiosError }
>(
  "limitFenceCards/updateSingleFenceCard",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await updateLimitFenceCard(params);

      return data;
    } catch (error: unknown) {
      return rejectWithValue(error as AxiosError);
    }
  }
);

export const approveSingleFenceCard = createAsyncThunk<
  ISingleLimitFenceCardResponse,
  number,
  { rejectValue: AxiosError }
>(
  "limitFenceCards/approveSingleFenceCard",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await approveLimitFenceCard(id);

      return data;
    } catch (error: unknown) {
      return rejectWithValue(error as AxiosError);
    }
  }
);

export const attachFilesToSingleFenceCard = createAsyncThunk<
  IFilesAttachResponse,
  { id: number; files: File[] },
  { rejectValue: AxiosError }
>(
  "limitFenceCards/attachFilesToSingleFenceCard",
  async ({ id, files }, { rejectWithValue }) => {
    try {
      const { data } = await attachFiles(LimitFenceCardsEndpoints.SINGLE_LIMIT_FENCE_CARD + `/${id}` + Files.ATTACH_FILES, files);
      return data;
    } catch (error: unknown) {
      return rejectWithValue(error as AxiosError);
    }
  }
);


const limitFenceCardsSlice = createSlice({
  name: "limitFenceCards",
  initialState,
  reducers: {
    resetCurrentLimitFenceCard(state) {
      if (state.currentLimitFenceCard?.materials) {
        state.currentLimitFenceCard.materials = []
      }
      state.currentLimitFenceCard = null;
    },
    changeCurrentLimitFenceCard(state, action) {
      state.currentLimitFenceCard = action.payload;
    },
    addMaterialsToFenceCard(state, action){
      if (state.currentLimitFenceCard?.materials) {
        state.currentLimitFenceCard.materials = action.payload
      }
    }
  },

  extraReducers(builder) {
    builder
      .addCase(fetchLimitFenceCards.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchLimitFenceCards.fulfilled, (state, action) => {
        state.isLoading = false;
        state.limitFenceCards = action.payload.data;
      })

      .addCase(fetchLimitFenceCardsByProject.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchLimitFenceCardsByProject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.projectLimitFenceCards = action.payload.data;
      })

      .addCase(fetchLimitFenceCardsByContract.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchLimitFenceCardsByContract.fulfilled, (state, action) => {
        state.isLoading = false;
        state.contractLimitFenceCards = action.payload.data;
      })

      .addCase(fetchSingleLimitFenceCard.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSingleLimitFenceCard.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentLimitFenceCard = action.payload.data;
        state.currentLimitFenceCard.materials = [];
      })

      .addCase(fetchLimitFenceCardContracts.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchLimitFenceCardContracts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.contractsLimitFenceCard = action.payload.data;
      })

      .addMatcher(isError, (state, action: PayloadAction<ErrorType>) => {
        state.isLoading = false;
        state.error = action.payload.response?.data.message;
      });
  },
});

export const limitFenceCardsSliceReducer = limitFenceCardsSlice.reducer;
export const { resetCurrentLimitFenceCard, changeCurrentLimitFenceCard, addMaterialsToFenceCard } = limitFenceCardsSlice.actions;
