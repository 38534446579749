import { useSearchParams } from "react-router-dom";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  ButtonGroup,
  Flex, Icon, IconButton,
  Menu, MenuButton,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import {ArrowBackIcon} from "@chakra-ui/icons";
import {
  OutlineButton,
  PrimaryButton,
} from "../../components/lib/button/Button";
import { BlockTitle } from "../../components/lib/title/Title";
import { ChakraTabs } from "../../components/lib/tabs/Tabs";
import { ChakraMenuList } from "../../components/lib/menu/ChakraMenuList";
import { MainLayout } from "../../components/MainLayout/MainLayout";
import { AdditionalInformationBlock } from "../../components/SingleOrderLetterComponents/AdditionalInformationBlock";
import { singleOrderLetterPageTabs } from "../../shared/mock/purchasePageData";
import {
  SingleOrderLetterInformationBlock
} from "../../components/SingleOrderLetterComponents/SingleOrderLetterInformationBlock";
import {ReactComponent as MenuIcon} from "../../images/svg/menu/more.svg";
import { ReactComponent as FileShare} from "../../images/svg/file/share.svg";
import { DownloadFilesModal } from "../../components/Modal/ModalRight/DownloadFilesModal";
import { MaterialsTable } from "../../components/SingleOrderLetterComponents/MaterialsTable";
import { AccessRights } from "../../shared/mock/sidebarContent";
import { OnboardingByPermission } from "../../components/OnboardingByPermission/OnboardingByPermission";

export function CreateOrderLetterPage({ filesModal, setFilesModal, goBack }: any) {
  const [searchParams, setSearchParams] = useSearchParams();
  const tabIndex = Number(searchParams.get("tab"));
  
  const setDefaultTabParam = (index: number): void => {
    index === 0
      ? searchParams.delete("tab")
      : searchParams.set("tab", String(index));

    setSearchParams(searchParams);
  };

  return (
    <MainLayout>
      <Flex direction="column" gap={10} p={6}>
        <Flex direction="column" gap={4}>
          <Breadcrumb
            fontSize={14}
            separator={<Box w={1} h={1} bg="#8089A2" borderRadius="50%" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink href="/profile">Главная</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink href="/purchase">Закупки</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink href="/purchase/order-letters">
                Распорядительные письма
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage color="#8089A2">
              <BreadcrumbLink>Создать распорядительное письмо</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>

          <Flex justify="space-between" align="center">
            <Flex align="center" gap={4}>
              <ArrowBackIcon boxSize={6} cursor="pointer" onClick={() => goBack()} />
              <BlockTitle>Создать распорядительное письмо</BlockTitle>
            </Flex>
            <ButtonGroup spacing={3}>
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<MenuIcon color="red.500" />}
                  variant="outline"
                  borderColor="#FAD7D8"
                  _active={{ bg: "#FAD7D8", borderColor: "#F1868B" }}
                />
                <ChakraMenuList
                  list={[
                    {
                      title: "Отозвать оплату",
                    },
                    {
                      title: "Печать заявки на выплату аванса",
                    },
                    {
                      title: "Создать отражение факта поставки",
                    },
                  ]}
                />
              </Menu>
              <OutlineButton
                leftIcon={<Icon as={FileShare} />}
                onClick={() => setFilesModal(true)}
              >
                Файлы
              </OutlineButton>
              <OutlineButton>Согласовать</OutlineButton>
              <OutlineButton>Перезапустить</OutlineButton>
              <PrimaryButton>Записать</PrimaryButton>
            </ButtonGroup>
          </Flex>

          <Tabs defaultIndex={tabIndex} onChange={setDefaultTabParam}>
            <ChakraTabs tabs={singleOrderLetterPageTabs} />
            <TabPanels>
              <TabPanel px={0} pb={0}>
                <SingleOrderLetterInformationBlock />
              </TabPanel>
              <TabPanel px={0} pb={0}>
                <MaterialsTable disabledChange={true}/>
              </TabPanel>
              <TabPanel px={0} pb={0}>
                <AdditionalInformationBlock setFilesModal={setFilesModal} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>

        <Box>
          <OnboardingByPermission permission={AccessRights.Purchase_RW} />
        </Box>

      </Flex>
      <DownloadFilesModal
        title="Файлы"
        active={filesModal}
        onClose={() => setFilesModal(false)}
        receivedFiles={[]}
        fileAttach={() => {}}
      />
    </MainLayout>
  );
}
