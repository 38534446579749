import { useEffect, useState } from "react";
import { ChakraModalProps } from "../../shared/types/modalProps";
import { ModalOverlay, ModalContent, Flex, Heading, ModalFooter, ModalHeader, Modal } from "@chakra-ui/react";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import { useLocation, useNavigate } from "react-router-dom";
import { right } from "@popperjs/core";
import { OutlineButton, PrimaryButton } from "../lib/button/Button";
import { resetCurrentContract } from "../../store/slices/contracts";
import { CreatCompensationActTable } from "../CompensationActsContractsTable/CompensationActsContractsTable"
import { fetchSavingCompensationActContracts } from "../../store/slices/savingsCompensationActs";
import { fetchLimitFenceCardContracts } from "../../store/slices/limitFenceCards";

export function CompensationActModal(props: Readonly<ChakraModalProps>) {
  const [disableButton, setDisableButton] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const {isOpen, onClose, isInsideCreate, isAok} = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSpinnerRunning, setIsSpinnerRunning] = useState<boolean>(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { contractsLimitFenceCard } = useAppSelector((state: RootState) => state.limitFenceCards);
  const { contractsSavingsCompensationAct } = useAppSelector((state: RootState) => state.savingsCompensationActsReducer);
  
  function onChangeLoading(value:boolean) {
    setIsLoading(value);
  }
  
  function changeStatusButtonDisabled(value: boolean) {
    setDisableButton(value);
  }
  
  const handleCreateClick = () => {
    setIsSpinnerRunning(true);
  }
  
  useEffect(() => {
    if (isSpinnerRunning) {
      setIsSpinnerRunning(false);
      if(!isInsideCreate){
        navigate(`${pathname}/create`);
      } else {
          onClose();
      }
    }
  }, [isSpinnerRunning, navigate, pathname, isInsideCreate, onClose]);

  useEffect(() => {
    isOpen && dispatch(isAok ? fetchSavingCompensationActContracts() : fetchLimitFenceCardContracts())
  }, [dispatch, isAok, isOpen])
  
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(3px)" />
      <ModalContent display="flex" justifyContent="space-between" flexDirection="column" maxW="80%" minH="96%" px="24px" py="32px">
        <ModalHeader color="#343B4C" fontWeight={400} p={0}>
          <Flex direction="column" align="left" gap="24px">
            <Heading lineHeight="28px" fontWeight="500" fontSize="24px" color="#000">Выберите договор</Heading>
            <CreatCompensationActTable data={isAok ? contractsSavingsCompensationAct : contractsLimitFenceCard} onChangeLoading={onChangeLoading} changeStatusButtonDisabled={changeStatusButtonDisabled}/>
          </Flex>
        </ModalHeader>
        <ModalFooter p={0}>
          <Flex justifyContent={right}>
            <OutlineButton w="full" onClick={() =>{
              onClose();
              dispatch(resetCurrentContract());
            }}>
              Отмена
            </OutlineButton>
            <PrimaryButton
              isDisabled={disableButton} 
              marginLeft="12px" 
              w="full"
              isLoading={isLoading}
              onClick={handleCreateClick}
            >
              Выбрать
            </PrimaryButton>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
    );
  }
