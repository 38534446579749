import {useNavigate} from "react-router-dom";
import {
  ButtonGroup,
  Flex,
  Grid,
  GridItem,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  Input as ChakraInput,
  FormControl, FormErrorMessage,
  Spinner,
  Box
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import {
  OutlineButton,
  PrimaryButton,
} from "../../components/lib/button/Button";
import { BlockTitle } from "../../components/lib/title/Title";
import { MainLayout } from "../../components/MainLayout/MainLayout";
import { Comment } from "../../components/Comment/Comment";
import {RootState, useAppDispatch, useAppSelector} from "../../store";
import React, {useCallback, useEffect, useState} from "react";
import {
  addMaterialsToFenceCard,
  approveSingleFenceCard, attachFilesToSingleFenceCard,
  fetchSingleLimitFenceCard,
  resetCurrentLimitFenceCard, updateSingleFenceCard
} from "../../store/slices/limitFenceCards";
import {
  SingleStockCardInformationBlock
} from "../../components/SingleStockCardComponents/SingleStockCardInformationBlock";
import { Input, InputThemes } from "../../components/lib/input/Input";
import { Loader } from "../../components/Loader/Loader";
import {ChakraModalProps} from "../../shared/types/modalProps";
import { ILimitFenceCardMaterial, ILimitFenceCardMaterials, IMaterialPivot } from "../../api/limitFenceCards/types";
import { DownloadFilesModal } from "../../components/Modal/ModalRight/DownloadFilesModal";
import {IFilesAttachResponse} from "../../api/files/types";
import {StockCardPageBreadcrumbs} from "../../components/Breadcrumbs/StockCardPageBreadcrumbs";
import { getSingleLimitFenceCardMaterials } from "../../api/limitFenceCards";
import {useFormik} from "formik";
import { ReactComponent as Document } from "../../images/svg/sidebar/document.svg";
import { v4 as uuidv4 } from 'uuid';
import { MaterialsTable } from "../../components/SingleOrderLetterComponents/MaterialsTable";
import { OnboardingByPermission } from "../../components/OnboardingByPermission/OnboardingByPermission";
import { AccessRights } from "../../shared/mock/sidebarContent";

interface IApproveStockCardModal extends ChakraModalProps {
  setIsRunSpinner: (isRunSpinner: boolean) => void;
}

interface IDivideMaterialModal extends ChakraModalProps {
  material?: ILimitFenceCardMaterial;
  rowIdx?: number;
  onDivideMaterialPeriod: () => void;
}

export function SingleStockCardPage({ goBack, id, dispatch, filesModal, setFilesModal }: any) {
  const [searchValue, setSearchValue] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [isEditable, setIsEditable] = useState(false);
  const [isSearchDisable, setIsSearchDisable] = useState(true)
  const [material, setMaterial] = useState<ILimitFenceCardMaterial | undefined>(undefined);
  const [disableApprove, setDisableApprove] = useState(true);
  const [rowIdx, setRowIdx] = useState<number | undefined>(undefined);
  const [isMaterialsLoading, setIsMaterialsLoading] = useState(true)

  const { currentLimitFenceCard} = useAppSelector(
    (state: RootState) => state.limitFenceCards
  );
  const [files, setFiles] = useState(currentLimitFenceCard?.files ?? []);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isMaterialModalOpen, onOpen: onMaterialModalOpen, onClose: onMaterialModalClose } = useDisclosure();

  const [dataState, setDataState] = useState({
    id: currentLimitFenceCard?.id ?? 0,
    period_from: "",
    period_to: "",
    comment: currentLimitFenceCard?.comment ?? "",
    materials: [{
      guid: "",
      material_id: "",
      additional_quantity: "",
      additional_delivery_period: "",
      additional_contractor_comment: ""
    }]
  });

  const [isRunSpinner, setIsRunSpinner] = useState(false);

  const onChangeData = useCallback((
    period_from_value: string,
    period_to_value: string,
  ) => {
    setDataState((dataState) => ({
      ...dataState,
      period_from: period_from_value,
      period_to: period_to_value,
    }));
  }, []);

  const onSubmit = () => {
    setIsRunSpinner(true);
    dispatch(updateSingleFenceCard(dataState))
      .then(() => goBack())
      .catch((e: Error) => {
        console.error('Error: ', e);
      })
      .finally(() => setIsRunSpinner(false));
  };

  const handleChangeComment = useCallback((comment_value: string) => {
    setDataState((dataState) => ({
      ...dataState,
      comment: comment_value,
    }));
  }, []);

  const fetchMaterialsPage = useCallback(async (page: number) => {
    return getSingleLimitFenceCardMaterials(Number(id), page).then(response => response.data.data);
  }, [id]);

  const loadMaterials = useCallback(async () => {
    const response = await fetchMaterialsPage(1);
    if (response) {
      let allMaterials: ILimitFenceCardMaterial[] = [];
      const totalPages = response.last_page;
      const allPromises: Promise<ILimitFenceCardMaterials>[] = [];

      for (let page = 1; page <= totalPages; page++) {
        allPromises.push(fetchMaterialsPage(page));
      }

      const pagesData = await Promise.all(allPromises);
      pagesData.forEach((pageData) => {
        allMaterials = allMaterials.concat(pageData.data);
      });
      dispatch(addMaterialsToFenceCard(allMaterials));
      setIsSearchDisable(false);
      setIsMaterialsLoading(false);
    }
  }, [dispatch, fetchMaterialsPage]);

  const setMaterialRow = (material: ILimitFenceCardMaterial | undefined, idx: number | undefined): void => {
    setMaterial(material);
    setRowIdx(idx);
  }

  const onDivideMaterialPeriod = () => {
    setMaterialRow(undefined, undefined);
    setDisableApprove(true);
    setDisabled(false);
  }

  const fileAttach = (files: File[]) => {
    if (currentLimitFenceCard) {
      dispatch(attachFilesToSingleFenceCard({id: currentLimitFenceCard.id, files: files})).then((res: { payload: IFilesAttachResponse}) => {
        setFiles((res.payload as IFilesAttachResponse)?.data.files);
      });
    }
  }

  useEffect(() => {
    dispatch(fetchSingleLimitFenceCard(Number(id)));
    loadMaterials();
  }, [dispatch, id, loadMaterials]);

  useEffect(() => {
    if (currentLimitFenceCard) {
      setDataState((dataState) => ({
        ...dataState,
        id: currentLimitFenceCard.id,
        materials: currentLimitFenceCard.materials
          ? currentLimitFenceCard.materials.map((material) => ({
            guid: material.pivot.guid,
            material_id: material.pivot.material_id,
            additional_quantity: material.pivot.amount,
            additional_delivery_period: material.pivot.period,
            additional_contractor_comment: material.pivot.comment
          }))
          : dataState.materials,
      }));

      setIsEditable(currentLimitFenceCard.status === "Рабочая");
      setFiles(currentLimitFenceCard.files ?? []);
    }
  }, [currentLimitFenceCard]);

  useEffect(() => {
    setDisableApprove(!isEditable);
  }, [isEditable]);

  useEffect(() => {
    if (isEditable) {
      setDisabled(!disableApprove || dataState.period_from === "" || dataState.period_to === "" || (Date.parse(dataState.period_from) > Date.parse(dataState.period_to)));
    }
  }, [dataState, disableApprove, isEditable]);

  return (
    <MainLayout>
      <Flex overflowY="scroll" maxH="100%" direction="column" gap={10} p={6}>
        <Flex direction="column" gap={4}>
          <StockCardPageBreadcrumbs currentPageText="Редактировать лимитно-заборную карту" />

          <Flex justify="space-between" align="center">
            <Flex align="center" gap={4}>
              <ArrowBackIcon boxSize={6} cursor="pointer" onClick={() => goBack("/purchase/stock-cards")} />
              <BlockTitle>Редактировать лимитно-заборную карту</BlockTitle>
            </Flex>
            <ButtonGroup spacing={3}>
              <OutlineButton isDisabled={disableApprove || !currentLimitFenceCard?.can_approve} onClick={onOpen}>Согласовать</OutlineButton>
              <OutlineButton isDisabled={true}>Создать отражение факта поставки</OutlineButton>
              <OutlineButton isDisabled={currentLimitFenceCard?.status!="Отклонена"}>Перезапустить</OutlineButton>
              <PrimaryButton isDisabled={disabled || currentLimitFenceCard?.status!="Рабочая"} onClick={onSubmit}>
                Записать
              </PrimaryButton>
            </ButtonGroup>
          </Flex>
          <Grid templateColumns="1fr 504px" gap={4}>
            <GridItem>
              <SingleStockCardInformationBlock onChangeData={onChangeData} setDisableApprove={setDisableApprove} />
            </GridItem>
            <GridItem>
              <Comment currentComment={currentLimitFenceCard?.comment} handleChangeComment={handleChangeComment} setDisableApprove={setDisableApprove} isEdited={isEditable} />
            </GridItem>
          </Grid>
          <Flex pt={6} gap={4} w="full" direction="column">
            <Flex justify="space-between">
              <Flex gap={6} align="center" maxW="458px">
                <BlockTitle>Материалы</BlockTitle>
                <Input
                  className={isSearchDisable ? InputThemes.Disabled : undefined}
                  placeholder="Поиск"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  search
                />
              </Flex>
              <ButtonGroup spacing={3}>
                <OutlineButton leftIcon={<Icon as={Document} fill={"#E8363D"} />} onClick={() => setFilesModal(true)}>Файлы</OutlineButton>
                <PrimaryButton isDisabled={!material || Number(material.pivot.amount) === 0} onClick={onMaterialModalOpen}>Разбить период</PrimaryButton>
              </ButtonGroup>
            </Flex>

            { isMaterialsLoading ? (
              <Spinner alignSelf="center" size='xl'/>
            ) : (
              <MaterialsTable searchValue={searchValue} setMaterialRow={setMaterialRow} setDisableApprove={setDisableApprove} />
            )
            }
          </Flex>
        </Flex>

        <Box>
          <OnboardingByPermission permission={AccessRights.Purchase_RW} />
        </Box>

      </Flex>
      {isRunSpinner && <Loader isLoading={isRunSpinner} />}
      <ApproveStockCardModal isOpen={isOpen} onClose={onClose} setIsRunSpinner={setIsRunSpinner} />
      <DownloadFilesModal
        title="Файлы"
        active={filesModal}
        onClose={() => setFilesModal(false)}
        receivedFiles={files}
        isDisabled={currentLimitFenceCard?.status=="Утверждена"}
        fileAttach={fileAttach}
      />
      <DivideMaterialModal isOpen={isMaterialModalOpen} onClose={onMaterialModalClose} material={material} rowIdx={rowIdx} onDivideMaterialPeriod={onDivideMaterialPeriod} />
    </MainLayout>
  );
}

function ApproveStockCardModal({onClose, isOpen, setIsRunSpinner}: IApproveStockCardModal) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { currentLimitFenceCard } = useAppSelector(
    (state: RootState) => state.limitFenceCards
  );

  const onApproveBtnClick = () => {
    setIsRunSpinner(true);
    currentLimitFenceCard?.id && dispatch(approveSingleFenceCard(currentLimitFenceCard?.id)).then(() => navigate(-1))
      .catch(e => {
        console.error('Error: ', e);
      })
      .finally(() => setIsRunSpinner(false));
  }

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(3px)"/>
      <ModalContent maxW="360px" gap="24px" px="24px" paddingTop="32px" paddingBottom="24px">
        <ModalHeader p={0}>
          <Flex flexDirection="column" gap="12px" align="center">
            <Text
              fontSize="24px"
              color="#343b4c"
              lineHeight="28px"
              letterSpacing="-1.5%"
              fontWeight={500}
              align="center">
              Согласовать?
            </Text>
            <Text fontSize={16} fontWeight={400} color="#5C657E" align="center">Обратите внимание, что вносить изменения в данную ЛЗК больше нельзя.</Text>
          </Flex>
        </ModalHeader>
        <ModalFooter p={0}>
          <Flex w="100%" gap="12px" align="center">
            <OutlineButton w="full" onClick={onClose}>
              Отмена
            </OutlineButton>
            <PrimaryButton w="full" onClick={onApproveBtnClick}>
              Согласовать
            </PrimaryButton>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function DivideMaterialModal({onClose, isOpen, material, rowIdx, onDivideMaterialPeriod}: IDivideMaterialModal) {
  const dispatch = useAppDispatch();
  const { currentLimitFenceCard } = useAppSelector(
    (state: RootState) => state.limitFenceCards
  );

  const handleClose = () => {
    formik.resetForm();
    onClose();
  }

  const onSubmit = (values: IMaterialPivot) => {
    let idxArr: number[] = [];
    const updatedMaterials: ILimitFenceCardMaterial[] = [];

    material && currentLimitFenceCard?.materials?.forEach((existingMaterial, idx) => {
      if (existingMaterial.pivot.order_number === material?.pivot.order_number) {
        idxArr.push(idx);
      }
      updatedMaterials.push(idx === rowIdx ? {...existingMaterial,  pivot: {
          ...existingMaterial.pivot,
          amount: (Number(material.pivot.amount) - Number(values.amount)).toFixed(3).toString(),
          guid: uuidv4()
        }} : existingMaterial);
    })

    material && updatedMaterials.splice(idxArr[idxArr.length - 1] + 1, 0, {...material, pivot: {...values, amount: Number(values.amount).toFixed(3).toString()}});

    dispatch(addMaterialsToFenceCard(updatedMaterials));
    onDivideMaterialPeriod();
    handleClose();
  };

  const {setFieldValue, ...formik} = useFormik({
    initialValues: {
      order_number: 0,
      limit_card_id: 0,
      material_id: "",
      amount: "",
      period: "",
      comment: "",
      guid: ""
    },
    onSubmit,
  });

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    let inputValue: string = e.target.value;
    const reg = /(?<=\.\d{3}|,\d{3})\d+/g;
    if (reg.test(e.target.value)) {
      inputValue = e.target.value.replace(reg, "");
    }
    setFieldValue('amount', inputValue);
  }

  useEffect(() => {
    setFieldValue("limit_card_id", material ? material?.pivot.limit_card_id : 0);
    setFieldValue("material_id", material ? material?.pivot.material_id : 0);
    setFieldValue("guid", material ? material?.pivot.guid : 0);
    setFieldValue("period", material ? material?.pivot.period : "");
  }, [material, setFieldValue]);

  return (
    <Modal isCentered isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay backdropFilter="blur(3px)"/>
      <ModalContent maxW="360px" gap="24px" px="24px" paddingTop="32px" paddingBottom="24px">
        <ModalHeader p={0}>
          <Flex flexDirection="column" gap="12px" align="center">
            <Text
              fontSize="24px"
              color="#343b4c"
              lineHeight="28px"
              letterSpacing="-1.5%"
              fontWeight={500}
              align="center">
              Разбить период
            </Text>
            <Text fontSize={16} lineHeight="20px" fontWeight={400} color="#5C657E" align="center">Вы должны указать количество меньше, чем текущее в разбиваемом периоде</Text>
            <Text fontSize={16} lineHeight="20px" fontWeight={500} color="#5C657E" align="center">Текущее количество: {material?.pivot.amount}</Text>
          </Flex>
        </ModalHeader>
        <ModalBody p={0}>
          <FormControl isInvalid={!!material?.pivot.amount && Number(formik.values.amount) >= Number(material?.pivot.amount)}>
            <ChakraInput
              id="amount"
              value={formik.values.amount}
              onChange={handleAmountChange}
              placeholder="Укажите количество"
              borderColor="#E3E7EF"
              type="number"
            />
            <FormErrorMessage>
              Вы указали недопустимое значение
            </FormErrorMessage>
          </FormControl>
        </ModalBody>
        <ModalFooter p={0}>
          <Flex w="100%" gap="8px" align="center">
            <OutlineButton w="full" onClick={handleClose}>
              Отмена
            </OutlineButton>
            <PrimaryButton w="full" onClick={() => onSubmit(formik.values)} isDisabled={!formik.values.amount || Number(formik.values.amount) >= Number(material?.pivot.amount)}>
              Разбить
            </PrimaryButton>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
